.store-locator-container .btn-storelocator-search {
  background-color: #f9f9f9;
  border-color: var(--skin-primary-color-1);
  border-radius: 0;
  color: var(--skin-primary-color-1); }
  .store-locator-container .btn-storelocator-search:hover {
    background-color: var(--skin-primary-color-1);
    color: #f9f9f9; }

.store-locator-container .detect-location {
  margin-bottom: 1em; }

.store-locator-container .store-name {
  font-weight: 600; }

.store-locator-container .store-details {
  display: block; }
  @media (min-width: 769px) {
    .store-locator-container .store-details {
      margin-left: 3.125em; } }

.store-locator-container .striped > div:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.store-locator-container .search-form {
  width: 100%;
  margin-bottom: 4rem; }

@media (min-width: 544px) {
  .store-locator-container .select-store {
    margin-top: -3rem; } }

.store-locator-container .custom-radio .store-details {
  margin-left: 1.125em; }

.store-locator-container .store-locator-no-results {
  display: none; }

@media (min-width: 769px) {
  .store-locator-container .results {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 21.875em; } }

.map-marker {
  float: left;
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 2em;
  border-radius: 0.375em;
  color: #fff;
  background-color: #7ed0ee;
  position: relative; }
  .map-marker::after {
    position: absolute;
    bottom: -0.625em;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 1em 0.5em 0;
    border-color: #7ed0ee transparent transparent transparent; }

.gm-style-iw .store-details {
  margin-left: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5; }

.gm-style-iw a {
  color: var(--skin-link-color-1); }

.map-canvas {
  display: none; }
  @media (min-width: 544px) {
    .map-canvas {
      height: 28.125em;
      display: block; } }

.store-name {
  font-weight: 600; }

.store-details {
  display: block;
  margin-left: 3.125em; }

.store-locator-no-apiKey {
  display: none; }

.store-locator-no-results {
  display: none; }

.store-locator-hero {
  background-image: url("../images/storelocator.jpg");
  margin-bottom: 0.625em; }
  @media (min-width: 544px) {
    .store-locator-hero {
      display: none; } }

@media (min-width: 769px) {
  .results {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 21.875em;
    margin-right: -0.938em; } }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.store-locator-container .btn-storelocator-search {
  background-color: #f8f8f8;
  border-color: var(--skin-primary-color-1);
  border-radius: 0;
  color: var(--skin-primary-color-1); }
  .store-locator-container .btn-storelocator-search:hover {
    background-color: var(--skin-primary-color-1);
    color: #f8f8f8; }

.store-locator-container .detect-location {
  margin-bottom: 1em; }

.store-locator-container .store-name {
  font-weight: 600; }

.store-locator-container .store-details {
  display: block; }
  @media (min-width: 769px) {
    .store-locator-container .store-details {
      margin-left: 3.125em; } }

.store-locator-container .striped > div:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.store-locator-container .search-form {
  width: 100%;
  margin-bottom: 4rem; }

@media (min-width: 544px) {
  .store-locator-container .select-store {
    margin-top: -3rem; } }

.store-locator-container .custom-radio .store-details {
  margin-left: 1.125em; }

.store-locator-container .store-locator-no-results {
  display: none; }

@media (min-width: 769px) {
  .store-locator-container .results {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 21.875em; } }

.store-locator-main .page-title {
  margin-bottom: 2.125rem; }

.sl-search-results {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  @media (min-width: 769px) {
    .sl-search-results {
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1; } }

.sl-map {
  display: none;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }
  .sl-map.js-show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  @media (min-width: 769px) {
    .sl-map {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
              order: 2; } }

.map-canvas {
  display: block;
  height: 24.375rem;
  width: 100vw;
  border-radius: 0;
  margin: 0 -1rem 1.5rem; }
  @media (min-width: 769px) {
    .map-canvas {
      height: 28.75rem;
      margin: 0 0 2rem;
      width: 100%;
      border-radius: 0.25rem; } }

.store-locator-container .store-locator-title,
.store-locator-main .store-locator-title {
  font-size: 1rem;
  line-height: 1.5rem; }
  @media (min-width: 992px) {
    .store-locator-container .store-locator-title,
    .store-locator-main .store-locator-title {
      font-size: 1.25rem;
      line-height: 1.75rem; } }

.store-locator-container .store-locator-detail,
.store-locator-main .store-locator-detail {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.5rem; }
  @media (min-width: 992px) {
    .store-locator-container .store-locator-detail,
    .store-locator-main .store-locator-detail {
      font-size: 1rem;
      line-height: 1.5rem; } }

.store-locator-container .search-form,
.store-locator-main .search-form {
  margin-bottom: 1.25rem; }

.store-locator-container .card,
.store-locator-main .card {
  border: 0; }
  @media (min-width: 769px) {
    .store-locator-container .card,
    .store-locator-main .card {
      border: 0.0625rem solid var(--color-grey3);
      margin: 0 0 1rem; } }
  .store-locator-container .card .card-body,
  .store-locator-main .card .card-body {
    padding: 0; }
    @media (min-width: 769px) {
      .store-locator-container .card .card-body,
      .store-locator-main .card .card-body {
        padding: 1.25rem; } }

.store-locator-container .sl-search-fields,
.store-locator-main .sl-search-fields {
  margin-bottom: 1.5rem; }
  @media (min-width: 769px) {
    .store-locator-container .sl-search-fields,
    .store-locator-main .sl-search-fields {
      border-bottom: 0.0625rem solid var(--color-grey3); } }

.store-locator-container .detect-location,
.store-locator-main .detect-location {
  width: auto;
  text-decoration: none;
  margin-bottom: 1rem; }
  .store-locator-container .detect-location .icon-position,
  .store-locator-main .detect-location .icon-position {
    margin-right: 0.5rem; }

.store-locator-container .store-search-bottom,
.store-locator-main .store-search-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .store-locator-container .store-search-bottom .search-radius,
  .store-locator-main .store-search-bottom .search-radius {
    margin-right: 1rem; }

.store-locator-container .btn-storelocator-search,
.store-locator-main .btn-storelocator-search {
  max-height: 3.75rem;
  background-color: var(--skin-btn-primary-bg);
  border-color: var(--skin-btn-primary-bord);
  color: var(--skin-btn-primary-txt);
  font-size: var(--skin-btn-font-size);
  padding: var(--skin-btn-padding);
  border-radius: var(--skin-btn-border-radius); }
  .store-locator-container .btn-storelocator-search:hover,
  .store-locator-main .btn-storelocator-search:hover {
    background-color: var(--skin-btn-primary-bg-hover);
    border-color: var(--skin-btn-primary-bord-hover);
    color: var(--skin-btn-primary-txt-hover); }
  .store-locator-container .btn-storelocator-search .find-store-btn-value,
  .store-locator-main .btn-storelocator-search .find-store-btn-value {
    font-size: 1rem;
    line-height: 1.25rem; }

.store-locator-container .store-locator-slot,
.store-locator-main .store-locator-slot {
  margin-bottom: 1.5rem; }
  .store-locator-container .store-locator-slot.liveCloseToWorkShop,
  .store-locator-main .store-locator-slot.liveCloseToWorkShop {
    padding: 1rem 1.875rem;
    background-color: var(--color-grey1);
    text-align: left; }
    @media (min-width: 769px) {
      .store-locator-container .store-locator-slot.liveCloseToWorkShop,
      .store-locator-main .store-locator-slot.liveCloseToWorkShop {
        text-align: center; } }
    .store-locator-container .store-locator-slot.liveCloseToWorkShop .headline,
    .store-locator-main .store-locator-slot.liveCloseToWorkShop .headline {
      font-size: 1.25rem;
      line-height: 1;
      margin-bottom: 0.625rem; }
    .store-locator-container .store-locator-slot.liveCloseToWorkShop .subline,
    .store-locator-main .store-locator-slot.liveCloseToWorkShop .subline {
      font-size: 0.875rem; }
    .store-locator-container .store-locator-slot.liveCloseToWorkShop .buildBear,
    .store-locator-main .store-locator-slot.liveCloseToWorkShop .buildBear {
      max-width: 300px;
      margin: 0 auto; }
      .store-locator-container .store-locator-slot.liveCloseToWorkShop .buildBear a.buildBear,
      .store-locator-main .store-locator-slot.liveCloseToWorkShop .buildBear a.buildBear {
        width: 100%;
        max-width: unset;
        padding: 0.75rem;
        display: block;
        background-color: var(--color-secondary); }
        .store-locator-container .store-locator-slot.liveCloseToWorkShop .buildBear a.buildBear:hover,
        .store-locator-main .store-locator-slot.liveCloseToWorkShop .buildBear a.buildBear:hover {
          background-color: var(--skin-btn-secondary-ol-txt-hover); }
        .store-locator-container .store-locator-slot.liveCloseToWorkShop .buildBear a.buildBear .the-bear-builder-heading-icon,
        .store-locator-main .store-locator-slot.liveCloseToWorkShop .buildBear a.buildBear .the-bear-builder-heading-icon {
          width: 8.75rem; }

.store-locator-container .store-locator-no-results,
.store-locator-main .store-locator-no-results {
  color: var(--color-grey6);
  margin-bottom: 1.875rem; }

.store-locator-container .no-results-msg,
.store-locator-main .no-results-msg {
  display: none; }

.store-locator-container .results-header,
.store-locator-main .results-header {
  margin-bottom: 1.5rem; }
  .store-locator-container .results-header .results-intro,
  .store-locator-main .results-header .results-intro {
    margin-bottom: 1.5rem; }
  .store-locator-container .results-header .results-count,
  .store-locator-main .results-header .results-count {
    font-size: 0.875rem; }
  .store-locator-container .results-header .search-result-terms,
  .store-locator-main .results-header .search-result-terms {
    display: none; }
  .store-locator-container .results-header .search-term,
  .store-locator-main .results-header .search-term {
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600; }
  .store-locator-container .results-header.mobile,
  .store-locator-main .results-header.mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 1.5rem;
    margin-bottom: 0;
    border-top: 0.0625rem solid var(--color-grey3); }
    @media (min-width: 769px) {
      .store-locator-container .results-header.mobile,
      .store-locator-main .results-header.mobile {
        display: none; } }
  .store-locator-container .results-header.desktop,
  .store-locator-main .results-header.desktop {
    display: none; }
    @media (min-width: 769px) {
      .store-locator-container .results-header.desktop,
      .store-locator-main .results-header.desktop {
        display: block; } }

.store-locator-container .results-card .results-list,
.store-locator-main .results-card .results-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .store-locator-container .results-card .results-list .card,
  .store-locator-main .results-card .results-list .card {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .store-locator-container .results-card .results-list .my-workshop,
  .store-locator-main .results-card .results-list .my-workshop {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
    .store-locator-container .results-card .results-list .my-workshop .preferred-ribbon,
    .store-locator-main .results-card .results-list .my-workshop .preferred-ribbon {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }

.store-locator-container .store-availability-form .form-group,
.store-locator-main .store-availability-form .form-group {
  width: 100%; }
  @media (max-width: 543.98px) {
    .store-locator-container .store-availability-form .form-group,
    .store-locator-main .store-availability-form .form-group {
      overflow: hidden; }
      .store-locator-container .store-availability-form .form-group .form-control-label,
      .store-locator-main .store-availability-form .form-group .form-control-label {
        white-space: nowrap; } }

.store-locator-container .store-locator-results,
.store-locator-main .store-locator-results {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.store-locator-container .prefered-store,
.store-locator-container .results,
.store-locator-container .store-landing-details,
.store-locator-main .prefered-store,
.store-locator-main .results,
.store-locator-main .store-landing-details {
  max-width: 50.625rem;
  max-height: none;
  margin-right: 0; }
  .store-locator-container .prefered-store.card,
  .store-locator-container .results.card,
  .store-locator-container .store-landing-details.card,
  .store-locator-main .prefered-store.card,
  .store-locator-main .results.card,
  .store-locator-main .store-landing-details.card {
    border: 0.0625rem solid var(--color-grey3);
    padding: 1rem; }
  .store-locator-container .prefered-store .preferred-ribbon,
  .store-locator-container .results .preferred-ribbon,
  .store-locator-container .store-landing-details .preferred-ribbon,
  .store-locator-main .prefered-store .preferred-ribbon,
  .store-locator-main .results .preferred-ribbon,
  .store-locator-main .store-landing-details .preferred-ribbon {
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600;
    display: none;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 0.75rem;
    color: var(--color-blue-dark);
    padding: 0 0.625rem;
    background: var(--skin-workshop-badge-color);
    position: relative;
    line-height: 1.1875rem;
    height: 1.25rem;
    white-space: nowrap;
    right: -1rem; }
    .store-locator-container .prefered-store .preferred-ribbon:before,
    .store-locator-container .results .preferred-ribbon:before,
    .store-locator-container .store-landing-details .preferred-ribbon:before,
    .store-locator-main .prefered-store .preferred-ribbon:before,
    .store-locator-main .results .preferred-ribbon:before,
    .store-locator-main .store-landing-details .preferred-ribbon:before {
      content: "";
      position: absolute;
      top: 0;
      right: 100%;
      width: 0;
      height: 0;
      border: 0.625rem solid transparent;
      border-right-color: var(--skin-workshop-badge-color); }
    @media (min-width: 769px) {
      .store-locator-container .prefered-store .preferred-ribbon,
      .store-locator-container .results .preferred-ribbon,
      .store-locator-container .store-landing-details .preferred-ribbon,
      .store-locator-main .prefered-store .preferred-ribbon,
      .store-locator-main .results .preferred-ribbon,
      .store-locator-main .store-landing-details .preferred-ribbon {
        right: -2.25rem; } }
    @media (min-width: 992px) {
      .store-locator-container .prefered-store .preferred-ribbon,
      .store-locator-container .results .preferred-ribbon,
      .store-locator-container .store-landing-details .preferred-ribbon,
      .store-locator-main .prefered-store .preferred-ribbon,
      .store-locator-main .results .preferred-ribbon,
      .store-locator-main .store-landing-details .preferred-ribbon {
        right: -1.25rem; } }
  .store-locator-container .prefered-store .myPrefferedWorkshop .preferred-ribbon,
  .store-locator-container .results .myPrefferedWorkshop .preferred-ribbon,
  .store-locator-container .store-landing-details .myPrefferedWorkshop .preferred-ribbon,
  .store-locator-main .prefered-store .myPrefferedWorkshop .preferred-ribbon,
  .store-locator-main .results .myPrefferedWorkshop .preferred-ribbon,
  .store-locator-main .store-landing-details .myPrefferedWorkshop .preferred-ribbon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .store-locator-container .prefered-store .store-name,
  .store-locator-container .results .store-name,
  .store-locator-container .store-landing-details .store-name,
  .store-locator-main .prefered-store .store-name,
  .store-locator-main .results .store-name,
  .store-locator-main .store-landing-details .store-name {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    color: var(--color-blue); }
    @media (min-width: 769px) {
      .store-locator-container .prefered-store .store-name,
      .store-locator-container .results .store-name,
      .store-locator-container .store-landing-details .store-name,
      .store-locator-main .prefered-store .store-name,
      .store-locator-main .results .store-name,
      .store-locator-main .store-landing-details .store-name {
        font-size: 1.25rem;
        line-height: 1.75rem; } }
    .store-locator-container .prefered-store .store-name .store-name-link,
    .store-locator-container .results .store-name .store-name-link,
    .store-locator-container .store-landing-details .store-name .store-name-link,
    .store-locator-main .prefered-store .store-name .store-name-link,
    .store-locator-main .results .store-name .store-name-link,
    .store-locator-main .store-landing-details .store-name .store-name-link {
      text-decoration: none; }
  .store-locator-container .prefered-store .store-full-address,
  .store-locator-container .results .store-full-address,
  .store-locator-container .store-landing-details .store-full-address,
  .store-locator-main .prefered-store .store-full-address,
  .store-locator-main .results .store-full-address,
  .store-locator-main .store-landing-details .store-full-address {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--color-grey6);
    margin-bottom: 0.75rem; }
    @media (min-width: 769px) {
      .store-locator-container .prefered-store .store-full-address,
      .store-locator-container .results .store-full-address,
      .store-locator-container .store-landing-details .store-full-address,
      .store-locator-main .prefered-store .store-full-address,
      .store-locator-main .results .store-full-address,
      .store-locator-main .store-landing-details .store-full-address {
        font-size: 1rem;
        line-height: 1.5rem; } }
  .store-locator-container .prefered-store .store-get-direction,
  .store-locator-container .results .store-get-direction,
  .store-locator-container .store-landing-details .store-get-direction,
  .store-locator-main .prefered-store .store-get-direction,
  .store-locator-main .results .store-get-direction,
  .store-locator-main .store-landing-details .store-get-direction {
    background-color: var(--color-grey1);
    padding: 1rem;
    margin: 0 -1.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600; }
    .store-locator-container .prefered-store .store-get-direction a,
    .store-locator-container .results .store-get-direction a,
    .store-locator-container .store-landing-details .store-get-direction a,
    .store-locator-main .prefered-store .store-get-direction a,
    .store-locator-main .results .store-get-direction a,
    .store-locator-main .store-landing-details .store-get-direction a {
      text-decoration: none; }
    .store-locator-container .prefered-store .store-get-direction .store-map,
    .store-locator-container .results .store-get-direction .store-map,
    .store-locator-container .store-landing-details .store-get-direction .store-map,
    .store-locator-main .prefered-store .store-get-direction .store-map,
    .store-locator-main .results .store-get-direction .store-map,
    .store-locator-main .store-landing-details .store-get-direction .store-map {
      font-size: 0.875rem;
      line-height: 1rem;
      text-decoration: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
  .store-locator-container .prefered-store .storelocator-phone,
  .store-locator-container .results .storelocator-phone,
  .store-locator-container .store-landing-details .storelocator-phone,
  .store-locator-main .prefered-store .storelocator-phone,
  .store-locator-main .results .storelocator-phone,
  .store-locator-main .store-landing-details .storelocator-phone {
    text-decoration: none;
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600; }
  .store-locator-container .prefered-store .store-phone-no,
  .store-locator-container .results .store-phone-no,
  .store-locator-container .store-landing-details .store-phone-no,
  .store-locator-main .prefered-store .store-phone-no,
  .store-locator-main .results .store-phone-no,
  .store-locator-main .store-landing-details .store-phone-no {
    padding: 1rem;
    margin: 0 -1.25rem; }
  .store-locator-container .prefered-store .store-details,
  .store-locator-container .results .store-details,
  .store-locator-container .store-landing-details .store-details,
  .store-locator-main .prefered-store .store-details,
  .store-locator-main .results .store-details,
  .store-locator-main .store-landing-details .store-details {
    margin-left: 0; }
  .store-locator-container .prefered-store .store-distance,
  .store-locator-container .prefered-store .storelocator-phone,
  .store-locator-container .prefered-store .instorePickUp-info,
  .store-locator-container .results .store-distance,
  .store-locator-container .results .storelocator-phone,
  .store-locator-container .results .instorePickUp-info,
  .store-locator-container .store-landing-details .store-distance,
  .store-locator-container .store-landing-details .storelocator-phone,
  .store-locator-container .store-landing-details .instorePickUp-info,
  .store-locator-main .prefered-store .store-distance,
  .store-locator-main .prefered-store .storelocator-phone,
  .store-locator-main .prefered-store .instorePickUp-info,
  .store-locator-main .results .store-distance,
  .store-locator-main .results .storelocator-phone,
  .store-locator-main .results .instorePickUp-info,
  .store-locator-main .store-landing-details .store-distance,
  .store-locator-main .store-landing-details .storelocator-phone,
  .store-locator-main .store-landing-details .instorePickUp-info {
    font-size: 0.875rem;
    line-height: 1.25rem; }
    @media (min-width: 769px) {
      .store-locator-container .prefered-store .store-distance,
      .store-locator-container .prefered-store .storelocator-phone,
      .store-locator-container .prefered-store .instorePickUp-info,
      .store-locator-container .results .store-distance,
      .store-locator-container .results .storelocator-phone,
      .store-locator-container .results .instorePickUp-info,
      .store-locator-container .store-landing-details .store-distance,
      .store-locator-container .store-landing-details .storelocator-phone,
      .store-locator-container .store-landing-details .instorePickUp-info,
      .store-locator-main .prefered-store .store-distance,
      .store-locator-main .prefered-store .storelocator-phone,
      .store-locator-main .prefered-store .instorePickUp-info,
      .store-locator-main .results .store-distance,
      .store-locator-main .results .storelocator-phone,
      .store-locator-main .results .instorePickUp-info,
      .store-locator-main .store-landing-details .store-distance,
      .store-locator-main .store-landing-details .storelocator-phone,
      .store-locator-main .store-landing-details .instorePickUp-info {
        font-size: 1rem;
        line-height: 1.5rem; } }

.store-locator-container .store-outStock,
.store-locator-main .store-outStock {
  color: var(--color-red); }

.store-locator-container .icon-maps-arrow-diagonal,
.store-locator-container .icon-phone,
.store-locator-main .icon-maps-arrow-diagonal,
.store-locator-main .icon-phone {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.375rem; }

.store-locator-container .icon-phone,
.store-locator-main .icon-phone {
  margin-left: 0.25rem; }

.store-locator-container .store-pickup,
.store-locator-main .store-pickup {
  font-size: 0.875rem; }
  .store-locator-container .store-pickup .curbside + .in-store:before,
  .store-locator-main .store-pickup .curbside + .in-store:before {
    content: '|';
    display: inline;
    margin: 0 0.625rem 0 0.3125rem; }

.store-locator-container .store-details-buttons,
.store-locator-main .store-details-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 1rem; }
  @media (min-width: 769px) {
    .store-locator-container .store-details-buttons .btn:not(:last-child),
    .store-locator-main .store-details-buttons .btn:not(:last-child) {
      margin-bottom: 0.625rem; } }
  .store-locator-container .store-details-buttons .btn-workshop-details,
  .store-locator-main .store-details-buttons .btn-workshop-details {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-bottom: 1.25rem;
    text-decoration: none;
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600; }
    .store-locator-container .store-details-buttons .btn-workshop-details .svg-icon,
    .store-locator-main .store-details-buttons .btn-workshop-details .svg-icon {
      margin-left: 0.1875rem;
      width: 1.375rem;
      height: 1.375rem; }

.store-locator-main .card {
  border: 0.0625rem solid var(--color-grey3); }
  .store-locator-main .card .card-body {
    padding: 1.25rem; }
  .store-locator-main .card .preferred-ribbon {
    right: -1.25rem; }

.modal-dialog-storelocator {
  max-width: 31.25rem; }
  @media (max-width: 543.98px) {
    .modal-dialog-storelocator {
      margin: 0 auto; } }
  .modal-dialog-storelocator .modal-content {
    border: 0;
    max-height: 100vh; }
    @supports (max-height: 100svh) {
      .modal-dialog-storelocator .modal-content {
        max-height: 100svh; } }
  .modal-dialog-storelocator .modal-header {
    -webkit-box-flex: 0;
        -ms-flex: 0;
            flex: 0; }
  .modal-dialog-storelocator .modal-storeLocator {
    overflow: auto;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding-bottom: 0; }
  .modal-dialog-storelocator .sl-modal-desc {
    font-family: SofiaPro, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: var(--color-grey6); }
  .modal-dialog-storelocator .store-locator-container .store-locator-title {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 0.5rem; }
  .modal-dialog-storelocator .store-locator-container .floating-label input {
    font-size: 1rem;
    line-height: 1.5rem; }
    @media (min-width: 992px) {
      .modal-dialog-storelocator .store-locator-container .floating-label input {
        font-size: 0.875rem;
        line-height: 1.25rem; } }
  .modal-dialog-storelocator .store-locator-container .store-availability-form .btn-storelocator-search {
    height: 3.75rem; }
  .modal-dialog-storelocator .store-locator-container .myPreferedWorkshop {
    padding-top: 0.5rem; }
    .modal-dialog-storelocator .store-locator-container .myPreferedWorkshop .form-group {
      border: 0.0625rem solid var(--color-blue);
      border-radius: 0.25rem;
      padding: 0; }
      .modal-dialog-storelocator .store-locator-container .myPreferedWorkshop .form-group.checked {
        background: var(--color-blue100); }
      .modal-dialog-storelocator .store-locator-container .myPreferedWorkshop .form-group label {
        display: block;
        padding: 0.75rem;
        padding-left: 2.75rem; }
      .modal-dialog-storelocator .store-locator-container .myPreferedWorkshop .form-group .custom-control-label:before {
        left: 0.75rem;
        top: 0.75rem; }
      .modal-dialog-storelocator .store-locator-container .myPreferedWorkshop .form-group .custom-control-label::after {
        left: 0.75rem;
        top: 0.75rem; }
      .modal-dialog-storelocator .store-locator-container .myPreferedWorkshop .form-group .custom-control-input:checked ~ .custom-control-label::after {
        left: 0.75rem; }
      .modal-dialog-storelocator .store-locator-container .myPreferedWorkshop .form-group .name {
        font-family: SofiaProBold, Arial, sans-serif;
        font-weight: 600; }
  .modal-dialog-storelocator .store-locator-container .card-body.checked .form-group {
    background: var(--color-blue100); }
  .modal-dialog-storelocator .modal-buttons {
    -webkit-box-flex: 0;
        -ms-flex: 0;
            flex: 0;
    -webkit-box-shadow: 0.25rem 0 0.625rem rgba(0, 0, 0, 0.08);
            box-shadow: 0.25rem 0 0.625rem rgba(0, 0, 0, 0.08);
    background-color: var(--color-white);
    padding: 1.25rem 0; }
    @media (min-width: 544px) {
      .modal-dialog-storelocator .modal-buttons {
        padding: 1.5rem 0; } }
  .modal-dialog-storelocator.plp .detect-location, .modal-dialog-storelocator.cart .detect-location {
    margin-bottom: 0; }
  .modal-dialog-storelocator.plp .stockMsg,
  .modal-dialog-storelocator.plp .stocksInfo, .modal-dialog-storelocator.cart .stockMsg,
  .modal-dialog-storelocator.cart .stocksInfo {
    display: none; }
  .modal-dialog-storelocator.plp .store-finders-box, .modal-dialog-storelocator.cart .store-finders-box {
    margin-bottom: 0.75rem; }
  .modal-dialog-storelocator.plp .store-locator-title, .modal-dialog-storelocator.cart .store-locator-title {
    display: none; }
  @media (min-width: 544px) {
    .modal-dialog-storelocator.plp .store-finders-box, .modal-dialog-storelocator.cart .store-finders-box {
      margin-bottom: 0; } }
  @media (min-width: 769px) {
    .modal-dialog-storelocator.plp .store-locator-titles, .modal-dialog-storelocator.cart .store-locator-titles {
      font-size: 1.25rem;
      line-height: 1.75rem; } }
  .modal-dialog-storelocator.plp .store-locator-no-results, .modal-dialog-storelocator.cart .store-locator-no-results {
    text-align: center;
    color: var(--color-red); }
  .modal-dialog-storelocator.plp .no-results-plp, .modal-dialog-storelocator.cart .no-results-plp {
    display: block; }
  .modal-dialog-storelocator.checkout .detect-location {
    display: none; }
  .modal-dialog-storelocator.checkout .store-locator-no-results {
    color: var(--skin-main-text-color); }
  .modal-dialog-storelocator.checkout .no-results-checkout {
    display: block; }

.gm-style-iw .store-details {
  margin-left: 0;
  font-family: SofiaPro, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  font-weight: normal; }
  .gm-style-iw .store-details .preferred-ribbon {
    display: none; }
  .gm-style-iw .store-details .store-full-address .address-line {
    display: block; }
  .gm-style-iw .store-details .store-distance {
    display: none; }
  .gm-style-iw .store-details .store-hours {
    display: none; }
  .gm-style-iw .store-details .store-details-bottom {
    margin-bottom: 0; }
  .gm-style-iw .store-details .store-details-buttons {
    margin-top: 0; }
    .gm-style-iw .store-details .store-details-buttons .btn:not(:last-child) {
      margin-bottom: 0; }
  .gm-style-iw .store-details .btn-workshop-details {
    margin-bottom: 0; }
  .gm-style-iw .store-details .store-book-party,
  .gm-style-iw .store-details .makeItMyWorkshopBtn {
    display: none; }

.product-detail .store-locator-container .results-card {
  border: 0;
  padding-top: 2.5rem; }

.store-hours .card {
  background-color: transparent;
  border: 0; }
  .store-hours .card .card-body {
    padding: 0; }

.store-hours .card-header {
  display: block;
  width: 100%;
  padding: 1.125rem 0;
  background-color: transparent; }
  .store-hours .card-header .card-title {
    float: left;
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 0; }
    @media (min-width: 769px) {
      .store-hours .card-header .card-title {
        font-size: 1rem;
        line-height: 1.5rem; } }
  .store-hours .card-header svg {
    float: left;
    margin: 0.125rem 0 0 0.625rem; }
  .store-hours .card-header.collapsed {
    border-bottom: 0; }
    .store-hours .card-header.collapsed svg {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

.store-hours .collapse .card-body {
  border: 0;
  display: none; }

.store-hours .collapse.show .card-body {
  display: block; }

.store-hours .store-availability-info.card-body {
  padding: 1rem 0 0; }
  .store-hours .store-availability-info.card-body ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 1rem;
    color: var(--color-grey6); }
    .store-hours .store-availability-info.card-body ul li {
      font-size: 0.875rem;
      margin-bottom: 0.75rem; }
      .store-hours .store-availability-info.card-body ul li.current-day,
      .store-hours .store-availability-info.card-body ul li.current-day p {
        font-family: SofiaProBold, Arial, sans-serif;
        font-weight: 600;
        color: var(--skin-main-text-color); }
      .store-hours .store-availability-info.card-body ul li p {
        font-size: 0.875rem;
        margin: 0; }
  .store-hours .store-availability-info.card-body > ul > li:first-child > br {
    display: none; }

.modal-storeLocator .store-hours .card {
  margin: 0; }

.modal-storeLocator .store-hours .card-header {
  padding: 0 0 1rem; }

.form-check-label .store-name {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--color-blue); }
  @media (min-width: 769px) {
    .form-check-label .store-name {
      font-size: 1.25rem;
      line-height: 1.75rem; } }

.form-check-label .store-full-address {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--color-grey6);
  margin-bottom: 0.5rem; }
  @media (min-width: 769px) {
    .form-check-label .store-full-address {
      font-size: 1rem;
      line-height: 1.5rem; } }

.form-check-label .store-get-direction {
  background-color: var(--color-grey1);
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .form-check-label .store-get-direction .store-map {
    font-size: 0.875rem;
    line-height: 1rem;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }

.form-check-label .storelocator-phone {
  text-decoration: none; }

.form-check-label .store-phone-no {
  margin-top: 1rem 0;
  padding: 1rem; }

.myPrefferedWorkshop .makeItMyWorkshopBtn {
  display: none; }

.myPrefferedWorkshop .inStock {
  color: var(--color-green); }

.myPrefferedWorkshop .outOfStock,
.myPrefferedWorkshop .unavailableStock {
  color: var(--color-red); }

.results-card .form-check {
  padding-left: 0; }

.results-card .form-check-label {
  width: 100%;
  background-color: var(--color-white); }
  .results-card .form-check-label .store-details {
    margin-left: 0; }

.results-card .card-body {
  padding: 1rem; }
  @media (min-width: 769px) {
    .results-card .card-body {
      max-width: 50.625rem; } }

.results-card > div:nth-of-type(odd) {
  background-color: var(--color-white); }

@media (min-width: 769px) {
  .results-card.results {
    overflow-y: initial;
    overflow-x: initial;
    max-height: none; }
    .results-card.results .card {
      border: 0; } }

.results-card .store-availability-accordion .card {
  border: 0; }

.results-card .collapse .card-body {
  border: 0;
  display: none; }

.results-card .collapse.show .card-body {
  display: block; }

.results-card .inStock {
  color: var(--color-green); }

.results-card .outOfStock,
.results-card .unavailableStock {
  color: var(--color-red); }

.modal-dialog-storelocator.plp .prefered-store, .modal-dialog-storelocator.checkout .prefered-store, .modal-dialog-storelocator.cart .prefered-store {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  margin-bottom: 0; }

.modal-dialog-storelocator.plp .results, .modal-dialog-storelocator.checkout .results, .modal-dialog-storelocator.cart .results {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }

.store-locator-container.store-landing .store-landing-details {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2; }
  @media (min-width: 769px) {
    .store-locator-container.store-landing .store-landing-details {
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1; } }
  .store-locator-container.store-landing .store-landing-details .page-heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media (min-width: 769px) {
      .store-locator-container.store-landing .store-landing-details .page-heading {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
    .store-locator-container.store-landing .store-landing-details .page-heading .ribbon-wrap {
      -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
              order: 2; }
      @media (min-width: 769px) {
        .store-locator-container.store-landing .store-landing-details .page-heading .ribbon-wrap {
          -webkit-box-ordinal-group: 2;
              -ms-flex-order: 1;
                  order: 1; } }
    .store-locator-container.store-landing .store-landing-details .page-heading .page-title {
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1; }
      @media (min-width: 769px) {
        .store-locator-container.store-landing .store-landing-details .page-heading .page-title {
          -webkit-box-ordinal-group: 3;
              -ms-flex-order: 2;
                  order: 2; } }

.store-locator-container.store-landing .store-landing-map {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }
  @media (min-width: 769px) {
    .store-locator-container.store-landing .store-landing-map {
      -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
              order: 2; } }

@media (min-width: 769px) {
  .store-locator-container.store-landing .preferred-ribbon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-bottom: 0.375rem;
    right: 0; }
    .store-locator-container.store-landing .preferred-ribbon:before {
      right: unset;
      left: 100%;
      border-color: transparent;
      border-left-color: var(--skin-workshop-badge-color); } }

.store-locator-container.store-landing .page-title {
  color: var(--color-blue);
  font-size: 2.5rem;
  margin-bottom: 0.75rem; }

.store-locator-container.store-landing .store-get-direction,
.store-locator-container.store-landing .store-phone-no {
  margin: 0 -1rem; }
  @media (min-width: 769px) {
    .store-locator-container.store-landing .store-get-direction,
    .store-locator-container.store-landing .store-phone-no {
      margin: 0; } }

.store-locator-container.store-landing .store-details-bottom {
  border-top: 0.0625rem solid var(--color-grey3);
  margin: 1rem 0;
  padding-top: 1rem; }

.store-locator-container.store-landing .store-details-buttons .btn {
  max-width: 17.9375rem; }

.store-locator-container.store-landing .store-details-buttons .btn-workshop-details {
  display: none; }

.store-locator-container.store-landing .about-store {
  border-top: 0.0625rem solid var(--color-grey3);
  padding-top: 1.5rem;
  margin-top: 1.25rem; }
  @media (min-width: 769px) {
    .store-locator-container.store-landing .about-store {
      border: 0;
      padding-top: 0;
      margin-top: 0; } }

.store-locator-container.store-landing .about-title {
  margin-bottom: 1.125rem; }

.store-locator-container.store-landing .store-desc {
  color: var(--color-grey6); }

.store-locator-container.store-landing .experience-main {
  width: 100%; }

.sitemap-list {
  -webkit-column-count: 1;
     -moz-column-count: 1;
          column-count: 1;
  margin: 1.875rem 0 3.125rem 1rem; }
  @media (min-width: 769px) {
    .sitemap-list {
      -webkit-column-count: 2;
         -moz-column-count: 2;
              column-count: 2;
      margin: 3.125rem 0 6.25rem 0; } }
  .sitemap-list .sitemap-list-stategroup {
    margin-bottom: 1rem;
    -webkit-column-break-inside: avoid;
       -moz-column-break-inside: avoid;
            break-inside: avoid-column; }
    .sitemap-list .sitemap-list-stategroup h2 {
      font-family: SofiaProThin, Arial, sans-serif;
      font-weight: 400; }
    .sitemap-list .sitemap-list-stategroup ul {
      line-height: 1.75rem;
      list-style: none; }
    .sitemap-list .sitemap-list-stategroup a {
      text-decoration: none; }
